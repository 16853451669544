import getConfig from 'next/config';
import NodeCache from 'node-cache';

const {
  publicRuntimeConfig,
} = getConfig();

export default new NodeCache({
  stdTTL: publicRuntimeConfig.cacheTTL,
});
