// @ts-ignore
const Header = ({
  markup,
}: {markup?: any}) => (
  <div
    dangerouslySetInnerHTML={
      {
        __html: markup,
      }
    }
  />
);

export default Header;
