import { NextPageContext } from 'next';

import cacheClient from '../utils/public/Cache';
import { getPublicStagedConfig } from '../utils/public/MintedConfig';
import Footer from './Footer';
import Header from './Header';

// eslint-disable-next-line react/display-name
const showHeaderFooter = (Component: any) => ({
  ...props
}) => (
  <>
    {/* app-renderer expects these variables to exist */}
    <div
      dangerouslySetInnerHTML = {
        {
          __html: `
          <script>
            window.M = {};
            window.M.IS_MOBILE = false;
            window.M.IS_MOBILE_OPTIMIZED = false;
            window.M.IS_MOBILE_USER_AGENT = false;
            window.M.IS_TABLET = false;
          </script>
      `,
        }
      }
    />
    <Header markup={props.markup} />
    <Component {...props} />
    <Footer markup={props.footerMarkup} />
  </>
);

showHeaderFooter.displayName = 'showHeaderFooter';

/**
 * Make a request to app renderer with path.
 * @param appRendererHost
 * @param path
 * @param shouldCache
 */
const appRendererRequest = async (appRendererHost: string, path: string, shouldCache: boolean) => {
  const cacheKey = `${appRendererHost}${path}`;

  let appRendererText = undefined;

  if (shouldCache) {
    appRendererText = cacheClient.get(cacheKey);
  }
  if (!appRendererText) {
    const appRendererResponse = await fetch(`${appRendererHost}${path}`, {
      method: 'GET',
    });

    if (appRendererResponse.status === 200) {
      appRendererText = await appRendererResponse.text();
      if (shouldCache && appRendererText) {
        cacheClient.set(cacheKey, appRendererText);
      }
    }
  }

  return appRendererText;
};

export function loadHeaderData() {
  return async (context: NextPageContext) => {
    const host = context?.req?.headers.host || '';
    const appRendererHost = getPublicStagedConfig(host, 'appRendererHost');
    const shouldCacheAppRenderer = getPublicStagedConfig(host, 'appRendererCache');
    const markup = await appRendererRequest(appRendererHost, '/render/header', shouldCacheAppRenderer);

    if (markup) {
      return {
        props: {
          markup,
        },
      };
    }

    return {
      props: {},
    };
  };
}

export function loadFooterData() {
  return async (context: NextPageContext) => {
    const host = context?.req?.headers.host || '';
    const appRendererHost = getPublicStagedConfig(host, 'appRendererHost');
    const shouldCacheAppRenderer = getPublicStagedConfig(host, 'appRendererCache');
    const footerMarkup = await appRendererRequest(appRendererHost, '/render/footer', shouldCacheAppRenderer);

    if (footerMarkup) {
      return {
        props: {
          footerMarkup,
        },
      };
    }

    return {
      props: {},
    };
  };
}

export default showHeaderFooter;
