import getConfig from 'next/config';

/**
 * Does not do regexp.  We expect explicit host names.
 */
interface StagedConfig {
  [host: string] : Record<string, string | boolean>
}

const stagedConfig : StagedConfig = {
  'login.mbo-devxenial.mntd.net': {
    appRendererCache: false,
    appRendererHost: 'https://app-renderer.test.mntd.net',
    clientId: '0oa3py77cjzkTlOZd1d7',
    cookieDomain: '.mbo-devxenial.mntd.net',
    cookieNamePrefix: 'mbo',
    monolithHost: 'https://mbo-devxenial.mntd.net',
  },
  'login.mbo.minted.com': {
    appRendererCache: true,
    appRendererHost: 'https://app-renderer-awsblue-prodxenial.minted.com',
    clientId: '0oa14mgzx6pCjJF6t697',
    cookieDomain: '.minted.com',
    cookieNamePrefix: 'mbo',
    monolithHost: 'https://mbo.minted.com',
  },
  'login.mbo.qa.mntd.net': {
    appRendererCache: true,
    appRendererHost: 'https://app-renderer.qa.mntd.net',
    clientId: '0oa3py77cjzkTlOZd1d7',
    cookieDomain: '.mbo.qa.mntd.net', // update to mbo when deploying to qa
    cookieNamePrefix: 'mbo',
    monolithHost: 'https://mbo.qa.mntd.net',
  },
  'login.mbo.test.mntd.net': {
    appRendererCache: true,
    appRendererHost: 'https://app-renderer.test.mntd.net',
    clientId: '0oa3py77cjzkTlOZd1d7',
    cookieDomain: '.mbo.test.mntd.net',
    cookieNamePrefix: 'mbo',
    monolithHost: 'https://mbo.test.mntd.net',
  },
  'login.qa.mntd.net': {
    appRendererCache: true,
    appRendererHost: 'https://app-renderer.qa.mntd.net',
    cookieDomain: '.qa.mntd.net',
    masqBookmarkUrl: 'https://minted.oktapreview.com/app/minted_corpmasquerading_1/exk2k52fbt1Nhgkhz1d7/sso/saml?RelayState=https%3A%2F%2Fminted-platform.oktapreview.com%2Fhome%2Fbookmark%2F0oa5o403gb840ej8W1d7%2F2557%0A',
    monolithHost: 'https://qa.mntd.net',
  },
  'login.qa1.mntd.net': {
    appRendererCache: true,
    appRendererHost: 'https://app-renderer.qa1.mntd.net',
    cookieDomain: '.qa1.mntd.net',
    monolithHost: 'https://qa1.mntd.net',
  },
  'login.test.mntd.net': {
    appRendererCache: true,
    appRendererHost: 'https://app-renderer.test.mntd.net',
    cookieDomain: '.test.mntd.net',
    monolithHost: 'https://test.mntd.net',
  },
  'login.web-devxenial.mntd.net': {
    appRendererCache: false,
    appRendererHost: 'https://app-renderer.test.mntd.net',
    cookieDomain: '.web-devxenial.mntd.net',
    masqBookmarkUrl: 'https://minted.oktapreview.com/app/minted_corpmasquerading_1/exk2k52fbt1Nhgkhz1d7/sso/saml?RelayState=https%3A%2F%2Fminted-platform.oktapreview.com%2Fhome%2Fbookmark%2F0oa5o4q1jqY3FNgb91d7%2F2557',
    monolithHost: 'https://web-devxenial.mntd.net',
  },
};

const getPublicStagedConfig = (host:string, key: string) => {
  const {
    publicRuntimeConfig,
  } = getConfig();

  /**
   * We chose to have a single staged environment to handle test, qa, and dev
   * This means some of the config will depend on what is in the window.location.host or
   * request.header.host fields.  For example, if you are on login.test.mntd.net, you want to
   * redirect to the test.mntd.net/login page.
   */
  if (stagedConfig.hasOwnProperty(host)) {
    const values = stagedConfig[host] || {};

    if (values.hasOwnProperty(key)) {
      return values[key];
    }
  }

  return publicRuntimeConfig[key];
};

const getWhitelistedIFrameOrigin = () => '*';
/**
   * This iFrame passes messages back to the parent opener. To keep these
   * messages secret, you usually define the origin as the parent url.
   * The problem here is that in test/qa/dev/temporary cu3 testing the url
   * may be:
   * - login.web-devxenial.mntd.net
   * - login.test.mntd.net
   * - login.qa.mntd.net
   * - review123.mntd.net
   * while prod will always be minted.com or could be customizer.minted.com
   * Getting the document.referrer won't work because you can click around from
   * /login -> /register -> blah so referrer won't stay the original parent
   * ancestorOrigins is only chrome
   * So this solves this by in prod we force to www.minted.com, in non prod
   * we will return a * meaning we don't care.
   * This is ok because currently, the only thing we send to parent is a string
   * "reset", "login", "register".  So if its intercepted, that's fine.
   * Do not change this to pass any secrets like tokens, etc or you will
   * have to revisit this
   */

export {
  getPublicStagedConfig,
  getWhitelistedIFrameOrigin,
};
