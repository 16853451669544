/*
These are the local paths when the system is in "full page" mode.
for the modal paths, see popupPaths.js
 */
const pagePaths = {
  login: '/',
  register: '/register',
  resetpass: '/resetpass',
};

export default pagePaths;
