import { getPublicStagedConfig } from './MintedConfig';

/**
 * currently we have all the whitelisting for redirects in the monolith /login.  So we are just
 * passing back what we go in the inital redirect to the /login.  It will then validate the redirect
 * and pass it on.  We should instead parse the window.location.search, validate against a whitelist
 * and redirect ourselves.  This will be implemented in the future.
 * IE the current process is
 * https://www.minted.com/login?destination=/design-vote/1234
 * redirects to:
 * https://login.minted.com/login?destination=/design-vote/1234
 * on success redirects to
 * https://www.minted.com/login?destination=/design-vote/1234
 * which notices you are logged in.  validates the /design-vote/1234 as relative and redirects to
 * https://www.minted.com/design-vote/1234
 *
 * in the future, write the whitelisting validation here, and skip step three.
 */
const getMonoUrl = (currentHost:string)=> getPublicStagedConfig(currentHost, 'monolithHost');
const clientMonoRedirect = ():void => {
  const host = getMonoUrl(window.location.host);

  window.location.assign(`${host}/login${window.location.search}`);
};

export {
  clientMonoRedirect,
  getMonoUrl,
};
