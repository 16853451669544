const Footer = ({
  markup,
}: {markup?: any}) => {
  if (!markup) {
    return null;
  }

  return (
    <div
      dangerouslySetInnerHTML={
        {
          __html: markup,
        }
      }
    />
  );
};

export default Footer;
