import Script from 'next/script';

// @ts-ignore
const CheckoutContentSquareTag = () => (
  <Script
    dangerouslySetInnerHTML = {
      {
        __html: `
        if(!window.clickTaleTagInjected){!function(d,t,u){clickTaleTagInjected = true;function injectTag(){var ns=d.createElementNS;var a=ns?ns.call(d,"http://www.w3.org/1999/xhtml",t):d.createElement(t),s=d.getElementsByTagName(t)[0];a.async=true;a.crossOrigin="anonymous";a.type="text/javascript";a.src=u;s.parentNode.insertBefore(a,s);} if(d.readyState!='loading'){injectTag();} else{d.addEventListener('DOMContentLoaded',function(){setTimeout(injectTag,0)});}}(document,'script','https://cdnssl.clicktale.net/www47/ptc/50e4dbea-db9c-4de7-a432-0b70aed744d9.js');}
        `,
      }
    }
    id="checkout-redirect-contentsquare-tag"
    strategy="afterInteractive"
  />
);

export default CheckoutContentSquareTag;
