/* eslint-disable no-undef */

// eslint-disable-next-line id-length
import merge from 'lodash/fp/merge';

import { HeaderNames } from '../constraints/headerNames';
import getCSRFToken from './csrfToken';

type RequestInitPlus = RequestInit & {
    csrf: boolean
}

const protectedMethods  = [
  'POST',
  'PUT',
  'PATCH',
  'DELETE',
];

const defaultInit = {
  headers: {
    'Content-Type': 'application/json',
  },
} as Partial<RequestInitPlus>;

export default async function fetch(input:RequestInfo, init?: Partial<RequestInitPlus>): Promise<Response> {
  let finalInit = merge(defaultInit, init);

  // if csrf is required or method is protected add xsrfToken header
  if (init?.csrf || (init?.method && protectedMethods.includes(init?.method))) {
    const token = await getCSRFToken();

    finalInit = merge(finalInit, {
      headers: {
        [HeaderNames.XSRFToken]: token,
      },
    } );
  }

  return window.fetch(input, finalInit);
}
